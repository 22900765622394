<template>
    <div class="p-2 fixed-columns">
        <div class="row mb-2 align-items-center">
            <div class="col-12 col-md-12 col-lg-6 align-self-center text-start mb-2 mb-md-0">
                <label class="form-label m-0">
                    <select v-model="meta.pageSize" style="border-color: #f0f2f5; border-radius: 0.375rem; padding: 3px;" :disabled="(isRefresh || loading)">
                        <option value="1">1</option>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <span class="ms-1">Data Perhalaman</span>
                </label>
            </div>

            <div class="col-12 col-md-12 col-lg-6 d-flex">
                <!-- pointer-events: none; -->
                <input-text-custom placeholder_="Cari..." v-model="search" @keyup="filtering" class_="rounded-0 rounded-start" :readonly_="isRefresh || loading"></input-text-custom>
                <button style="font-size: 1rem !important;padding-top: 0px;padding-bottom: 0px;padding-left: 10px;padding-right: 12px;" :class="['btn btn-primary m-0 rounded-0 rounded-end text-center',(loading) ? 'disabled':'']" @click="filteringByButton"><font-awesome-icon :icon="['fas', 'search']" /></button>
            </div>
        </div>
            <div class="table-responsive border border-1 rounded" style="min-height:400px;max-height: 475px;" ref="theTable">
            <table class="table table-bordered" id="dataTable-table">
                <thead class="table-info" style=" position: sticky; top: 0;z-index: 100;">
                    <tr>
                        <th v-for="(item, index) in headerFields" :class="['move-this p-2 text-black align-content-center ',(item.checkbox) ? 'text-center':'']" :key="'head-'+index+'-'+item.sort" style="position: relative; min-width: 10px;">
                            <slot v-if="!!$slots[item.key+'-header']" :name="item.key+'-header'" />
                            <template v-else>
                                <a class="user-select-none" v-if="!item.checkbox">{{ item.label }} &nbsp;</a>
                                <a v-if="item.sorting" class="" href="javascript:void(0)" @click="sortedItem(index)">
                                    <font-awesome-icon :icon="(item.sort == 'asc' ? ['fas', 'sort-amount-up-alt']: ['fas', 'sort-amount-up'])" />
                                </a>
                                <a v-if="item.checkbox" :class="['d-inline-block border border-1 text-center rounded text-white lh-sm ', (isCheckAllitem) ? 'bg-primary' : 'bg-white border-dark' ]" @click="checkAllItem()" href="javascript:void(0)" style="width: 25px !important;height: 25px !important;">
                                    <font-awesome-icon :icon="['fas', 'check']" class="align-middle"/>
                                </a>
                            </template>
                        </th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-if="loading">
                        <td :colspan="headerFields.length">
                            <div class="text-center my-4">
                                <div class="loader-dots mx-auto"></div>
                                <!-- <img src="/img/illustrations/toothless-dancing.gif" width="150"><br> -->
                                <span class="text-secondary fw-medium">Tunggu Bentar Ya, Sedang Memuat Data....</span>
                            </div>
                        </td>
                    </tr>
                    <template v-else>
                        <tr v-if="dataFields.length <= 0" class="border">
                            <td class="text-center py-4" :colspan="headerFields.length">
                                <img src="/img/illustrations/empty-data.svg" style="width: 225px;">
                                <div class="m-0 text-danger text-900" style="font-size: 1.2rem !important;">Yahh, Kosong :(</div>
                                <div class="m-0 text-secondary">Tidak Ada Data Ditemukan</div>
                            </td>
                        </tr>

                        <tr v-else v-for="(data, index) in dataFields" :key="index+'-tr'">
                            
                            <td :class="'align-content-center ' + (item.isNotTruncate ? 'text-nowrap ' : 'cut-text ') + (item.position ? item.position: '')" v-for="(item, index2) in headerFields" :key="index+'-td-head-'+index2">
                                <slot v-if="!!$slots[item.key]" :name="item.key" :item="data" />
                                <template v-else-if="item.inputType">
                                    <input-number-custom v-if="(item.inputType).toLowerCase() == 'number'" title_="" classGroup_="input-custom" v-model="data[item.key]"></input-number-custom>
                                    <input v-else type="text" class="input-custom p-2 w-full " v-model="data[item.key]" :placeholder="item.label">
                                </template>

                                <template v-else-if="item.date">
                                    <div class="m-1" v-if="(item.date).toLowerCase() == 'date'">{{ formatToDate(data[item.key]) }}</div>
                                    <div class="m-1" v-else-if="(item.date).toLowerCase() == 'datetime'">{{ formatToDateTime(data[item.key]) }}</div>
                                    <div class="m-1" v-else-if="(item.date).toLowerCase() == 'time'">{{ formatToTime(data[item.key]) }}</div>
                                </template>
                                
                                <template v-else-if="item.currency">
                                    <div class="m-1">{{ item.key ? formatNumberToCurrency(data[item.key]) : (meta.page - 1) * meta.pageSize + 1 + index++ }}</div>
                                </template>

                                <template v-else>

                                    <div class="m-1 text-truncate">{{ item.key ? data[item.key] : (meta.page - 1) * meta.pageSize + 1 + index++ }}</div>
                                </template>
                                
                            </td>
                        </tr>

                    </template>
                </tbody>
        
            </table>    
        </div>

        <div class="row mt-2">
            <div class="col-12 col-md-2 text-nowrap align-self-center text-end text-md-start">
                <button class="btn py-1 px-2 btn-primary m-0" @click="refresh" :disabled="isRefresh || loading">
                    <img :class="[(isRefresh || loading) ? 'kurukuru' : '']" v-if="search == 'GUNTER'" src="https://64.media.tumblr.com/b9a552bef486726fb1206750e50c643e/tumblr_mq4c74lZ6S1rwai13o1_500.gif" width="20"> 
                    <font-awesome-icon v-else :icon="['fas', 'sync-alt']" :spin="isRefresh || loading" class="me-2"/> Memuat Ulang
                </button>
            </div>

            <div class="col-12 col-md-10 mt-3 mt-md-0 text-end align-self-center">
                <nav class="align-self-end float-end">
                    <ul class="p-0 m-0">
                        <li class="pager" v-tippy="'Pertama'">
                            <a :class="['page-link ',(meta.page <= 1) ? 'disabled':(isRefresh || loading) ? 'disabled':'']" @click="load(1)">
                                <font-awesome-icon :icon="['fas', 'angle-double-left']" />
                            </a>
                        </li>
                        <li class="pager" v-tippy="'Sebelumnya'">
                            <a :class="['page-link ',(meta.page <= 1) ? 'disabled':(isRefresh || loading) ? 'disabled':'']" @click="load(meta.page - 1)"><font-awesome-icon :icon="['fas', 'angle-left']" /></a>
                        </li>

                        <li :class="['pager ', meta.page === n ? 'active' : '',(isRefresh || loading) ? 'disabled':'']" v-for="n in paginationLimit" :key="n">
                            <a class="page-link" @click="load(n)">{{ n }}</a>
                        </li>

                        <li class="pager" v-tippy="'Berikutnya'">
                            <a :class="['page-link ',(meta.page >= meta.totalPages) ? 'disabled':(isRefresh || loading) ? 'disabled':'']" @click="load(meta.page + 1)"><font-awesome-icon :icon="['fas', 'angle-right']" /></a>
                        </li>
                        <li class="pager" v-tippy="'Terakhir'">
                            <a :class="['page-link ',(meta.page >= meta.totalPages) ? 'disabled':(isRefresh || loading) ? 'disabled':'']" @click="load(meta.totalPages)">
                                <font-awesome-icon :icon="['fas', 'angle-double-right']" />
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
export default {
    props: {
        initialFields_: { type: Array, required: true },
        api_:           { type: Object, required: true },
        localStorage_:  { type: Boolean, default: true },
        CheckedAllItem_: { type: Function, default:null}
    },
    data: () => {
        return {
            fields: [],
            dataFields: [],
            headerFields: [],
            search: "",
            delayTimer: 0,
            meta: {
                pageSize: 25,
                totalPages: 1,
                count: 0,
                page: 1,
                nextPageLimitCounter:1
            },
            paginationLimit : Array.from({ length: 1 }, (_, index) => index + 1),
            loading: false,
            isRefresh: false,
            isCheckAllitem: false,
        };
    },
    async beforeMount() {
        this.search = this.$store.state[this.$router.currentRoute.value.path +'search'];
        this.headerFields = this.initialFields_.map((item) => { return { ...item, sort: "asc" }; });
        await this.load();
        
        this.moveHeader();
        this.formatToDate();
    },
    mounted(){
        this.isCheckAllitem = false;
    },
    computed: {
        // meta() {
        //     const count = this.meta.step + (this.meta.show*this.meta.step);
        //     return count > this.meta.lastPage ? (this.meta.lastPage - (this.meta.show*this.meta.step)): this.meta.step; 
        // },
    },
    methods: {
        scrollToTop(){
            const tableContainer = this.$refs.theTable;
            if (tableContainer) {
                tableContainer.scrollTop = 0;
            }
        },  
        checkAllItem(){
            if(this.loading) return;
            this.isCheckAllitem = !this.isCheckAllitem;
            this.CheckedAllItem_(this.isCheckAllitem);           
        },
        moveHeader() {
            let thElm;
            let startOffset;
            if(!document.getElementById("dataTable-table")) return;
            let divTh = document.getElementById("dataTable-table").offsetHeight;
            Array.prototype.forEach.call(document.querySelectorAll("th.move-this"), function (th) {
                var grip = document.createElement("div");
                grip.innerHTML = "&nbsp;";
                grip.classList.add("move-header");
                grip.style.height = divTh + "px";
                grip.addEventListener("mousedown", function (e) {
                    thElm = th;
                    startOffset = th.offsetWidth - e.pageX;
                    // stop event to be forwarded to parent component
                    e.stopPropagation();
                });
                grip.addEventListener("click", function (e) {
                    // stop event to be forwarded to parent component
                    e.stopPropagation();
                });
                th.appendChild(grip);
            });
            document.addEventListener("mousemove", function (e) {
                if (thElm) {
                    thElm.style["min-width"] = startOffset + e.pageX + "px";
                }
            });
            document.addEventListener("mouseup", function () {
                thElm = undefined;
            });
        },
        filtering(e) {
            
            if (this.delayTimer) {
                clearTimeout(this.delayTimer);
            }
            if (e.key == "Enter") {
                clearTimeout(this.delayTimer);
                this.$store.state[this.$router.currentRoute.value.path +'search'] = this.search;
                this.load();
                return;
            }
            this.delayTimer = setTimeout(() => {
                this.$store.state[this.$router.currentRoute.value.path +'search'] = this.search;
                this.load();
            }, 1500);
        },
        filteringByButton(){
            this.load();
        },
        getPaginationLimit(){
            const limit = 5;
            if(this.meta.page >= limit){
                // then
            }
            return limit;
        },
        async load(page = 1) {
            this.isCheckAllitem = false;
            this.meta.page = page;
            
            if(this.api_.meta != null) this.meta.pageSize = this.api_.meta.pageSize ?? this.meta.pageSize;            

            const param = {
                ...this.api_.parameter,
                pageNumber: page,
                pageSize: this.meta.pageSize
            };
            if (this.search) {
                // this.$store.state[this.$router.currentRoute.value.path]['search'] = this.search;
                // var data = this.$store.state[this.$router.currentRoute.value.path]
                param.search = this.search;
            }
            
            
            try {
                this.loading = true;    
                
                // await new Promise((resolve) => setTimeout(resolve, 1200));

                const response = await this.fetchData_(this.api_.model, param);
                this.dataFields = response.Data;
                this.meta.count = response.TotalCount;
                this.meta.page = response.PageNumber;
                this.meta.totalPages = response.TotalPages;
            
                this.paginationLimit = Array.from({ length: (this.meta.totalPages >= 5) ? 5 : this.meta.totalPages }, (_, index) => index + this.meta.nextPageLimitCounter);
                const halfPageNumbers = Math.floor(5 / 2);

                let startPage = this.meta.page - halfPageNumbers;
                let endPage = this.meta.page + halfPageNumbers;
            
                if (startPage < 1) {
                    startPage = 1;
                    endPage = Math.min(5, this.meta.totalPages);
                }
                if (endPage > this.meta.totalPages) {
                    endPage = this.meta.totalPages;
                    startPage = Math.max(1, endPage - 5 + 1);
                }

                this.paginationLimit = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

                this.scrollToTop();
                this.$emit('get-data', this.dataFields);
            }   
            catch (error) {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            }
            // html:'<img src="/img/illustrations/toothless-dancing.gif" width=150><span>Ya Maap, Server Kami sedang Down</span>',
            this.loading = false;
            this.isRefresh = false;
        },
        sortedItem(index) {
            const flip = (this.headerFields[index].sort == "asc" ? 1 : -1);
            const key = this.headerFields[index].key;
            var data = [...this.dataFields];
            data = data.sort((item, item2) => {
                const value = item[key];
                const value2 = item2[key];
                const a = typeof value === "number" ? value : String(value).toLowerCase();
                const b = typeof value2 === "number" ? value2 : String(value2).toLowerCase();
                return a > b ? 1 * flip : b > a ? -1 * flip : 0;
            });
            this.headerFields[index].sort = (this.headerFields[index].sort == "asc" ? "desc" : "asc");
            this.dataFields = data;
        },
        refresh(){
            this.isRefresh = true;
            this.load();
        },
        formatToDate(input){
            return moment.utc(input).local().format("DD/MM/yyyy");
        },
        formatToTime(input){
            return moment.utc(input).local().format("HH:mm");
        },
        formatToDateTime(input){
            return moment.utc(input).local().format("DD/MM/yyyy HH:mm");
        }
    }, 
    watch: {
        'meta.pageSize'() {
            this.load();
        }
    }
}
</script>

<style scoped>
/* Define the keyframes for rotation */
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Apply the animation to the image */
.kurukuru {
  animation: rotate 3s linear infinite; /* Rotate every 3 seconds infinitely */
}

.cut-text {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

</style>